import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './CareersPage.module.scss'
import { richTextOptions } from '@components/Markdown'
import truncate from 'truncate-html'
import Picture from '@components/Picture'
import Text from '@components/Text'
import GradientText from '@components/GradientText'
// import TeamContainer from '../IndexPage/TeamContainer'
// import { parseImage, parseAnchor } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

import Layout from '@components/Layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderToString } from 'react-dom/server'
import EmployeeHighlight from '@components/EmployeeHighlight'

const cx = classnames.bind(styles)

const goToLink = url => {
  window.open(url)
}

const CareersPage = ({ data, location }) => {
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  const content = data.contentfulCareersPage
  const highlights = data.contentfulCareersPage.employeeHighlights
  let healthArr = content.healthAndWellness.healthAndWellness.split('|')
  let workArr = content.workLifeBalance.workLifeBalance.split('|')
  let financialArr = content.financialWellbeing.financialWellbeing.split('|')
  let extrasArr = content.extras.extras.split('|')

  const [pageDisplayed, setPageDisplayed] = useState('Benefits')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const contentString = params.get('t')
    const reroutedTab = JSON.parse(contentString)
    if (reroutedTab == 'M') {
      changePageContent('Meet Our People')
    } else {
      changePageContent('Benefits')
    }
  }, [])

  const changePageContent = pageRequested => {
    setPageDisplayed(pageRequested)
  }

  content.companies.sort(function(a, b) {
    return a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
  })

  const companiesComp = documentToReactComponents(content.companiesParagraph.json, richTextOptions)
  const companiesStr = renderToString(companiesComp)
  const benefitsComp = documentToReactComponents(content.benefitsDescription.json, richTextOptions)
  const benefitsStr = renderToString(benefitsComp)

  let propertyComps = []
  let lifeComps = []
  let healthComps = []
  let specialtyComps = []

  for (let company of content.companies) {
    if (company.insuranceCategory.includes('Property')) {
      propertyComps.push(company)
    } else if (company.insuranceCategory.includes('Life')) {
      lifeComps.push(company)
    } else if (company.insuranceCategory.includes('Health')) {
      healthComps.push(company)
    } else if (company.insuranceCategory.includes('Specialty')) {
      specialtyComps.push(company)
    }
  }

  const finishBenefitsContent = () => {
    return (
      <div className={cx('benefits-con')}>
        <div className={cx('about-img-con')}>
          <Picture
            className={cx('about-img')}
            src={content.aboutUsImage.file.url}
            alt={content.aboutUsImage.description}
          />
        </div>
        <div className={cx('benefits-text-con')}>
          <div className={cx('content-subtitle-con')}>
            <GradientText
              tag="h3"
              className={cx('content-subtitle')}
              type="h3"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            ></GradientText>
          </div>
          <div className={cx('content-paragraph-con')}>
            <Text tag="p" type="b1" className={cx('content-paragraph')}>
              <div className={cx('')} dangerouslySetInnerHTML={{ __html: benefitsStr }} />
            </Text>
          </div>

          <Text tag="p" type="b1" className={cx('caps-bold', 'health-title')}>
            {'HEALTH AND WELLNESS'}
          </Text>
          <div className={cx('points-con-health')}>
            {healthArr.map(function(item, i) {
              return (
                <div key={i} className={cx('bullet-and-point-con')}>
                  <img src="/images/ms/check64.png" className={cx('check-icon')} alt="check" />
                  <Text key={i} tag="p" type="b2" className={cx('bullet-point')}>
                    {item}
                  </Text>
                </div>
              )
            })}
          </div>

          <Text tag="p" type="b1" className={cx('caps-bold', 'work-title')}>
            {'WORK-LIFE BALANCE'}
          </Text>
          <div className={cx('points-con-work')}>
            {workArr.map(function(item, i) {
              return (
                <div key={i} className={cx('bullet-and-point-con')}>
                  <img src="/images/ms/check64.png" className={cx('check-icon')} alt="check" />
                  <Text key={i} tag="p" type="b2" className={cx('bullet-point')}>
                    {item}
                  </Text>
                </div>
              )
            })}
          </div>

          <Text tag="p" type="b1" className={cx('caps-bold', 'financial-title')}>
            {'FINANCIAL WELLBEING'}
          </Text>
          <div className={cx('points-con-financial')}>
            {financialArr.map(function(item, i) {
              return (
                <div key={i} className={cx('bullet-and-point-con')}>
                  <img src="/images/ms/check64.png" className={cx('check-icon')} alt="check" />
                  <Text key={i} tag="p" type="b2" className={cx('bullet-point')}>
                    {item}
                  </Text>
                </div>
              )
            })}
          </div>

          <Text tag="p" type="b1" className={cx('caps-bold', 'extras-title')}>
            {'EXTRAS'}
          </Text>
          <div className={cx('points-con-extras')}>
            {extrasArr.map(function(item, i) {
              return (
                <div key={i} className={cx('bullet-and-point-con')}>
                  <img src="/images/ms/check64.png" className={cx('check-icon')} alt="check" />
                  <Text key={i} tag="p" type="b2" className={cx('bullet-point')}>
                    {item}
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const finishCompaniesContent = () => {
    return (
      <div>
        <div className={cx('side-scroll-con')}>
          <img className={cx('circles-img')} src="/images/ms/circles4.png" alt="Group 1001 Companies" />
          <div className={cx('all-logos-con')}>
            <div className={cx('logos-con', 'logos-con-property')}>
              {propertyComps.map(function(item, i) {
                if (item.companyName == 'Lackawanna (DIAGRAM ONLY)') {
                  item.companyName = 'Clear Spring Insurance'
                }
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-life')}>
              {lifeComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-health')}>
              {healthComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-specialty')}>
              {specialtyComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div className={cx('companies-con')}>
          {content.companies.map(function(item, i) {
            if (item.logo.description != 'Lackawanna Insurance Group') {
              return (
                <div key={i} className={cx('companies-company-con')}>
                  <a id={item.companyName}></a>
                  <div
                    key={i}
                    className={content.hideCompanyImages ? cx('disp-none') : cx('companies-company-img-con')}
                  >
                    <img
                      className={cx('companies-company-img')}
                      src={item.marketingImage.file.url}
                      alt={item.marketingImage.description}
                    />
                  </div>
                  <div className={cx('companies-company-text-con')}>
                    <div className={cx('clickable-logo-con')} onClick={() => goToLink(item.companyWebsite.url)}>
                      <img
                        className={cx('companies-company-logo')}
                        src={item.logo.file.url}
                        alt={item.logo.description}
                      />
                    </div>
                    {/* <a href={item.companyWebsite.url} className={cx('companies-company-link')}>
                    <Text tag="p" type="b2" className={cx('companies-company-link')}>
                      {item.companyWebsite.url}
                    </Text>
                  </a> */}
                    <div
                      className={cx('companies-company-about-text')}
                      dangerouslySetInnerHTML={{
                        __html: renderToString(documentToReactComponents(item.about.json, richTextOptions)),
                      }}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const finishMeetOurPeopleContent = () => {
    return (
      <div className={cx('content-subtitle-con')}>
        <GradientText
          tag="h3"
          className={cx('content-subtitle')}
          type="h3"
          colors={[gradientColor1, gradientColor2, gradientColor3]}
          colorStops={[gradientStop1, gradientStop2, gradientStop3]}
        >
          {'Employee Highlights'}
        </GradientText>
        <div className={cx('highlights-con')}>
          {highlights &&
            highlights.map(item => {
              const info = item.moreInfo ? documentToReactComponents(item.moreInfo.json, richTextOptions) : ''

              return (
                <EmployeeHighlight
                  key={item.image.url}
                  richExcerpt={truncate(renderToString(info), 320)}
                  moreInfo={item.moreInfo.json}
                  className="classNameTest"
                  {...item}
                ></EmployeeHighlight>
              )
            })}
        </div>
      </div>
    )
  }

  const tempShowBottomLinks = () => {
    if (pageDisplayed == 'Benefits') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => goToLink(content.careersLink)}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Careers'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Companies')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Companies >'}
            </Text>
          </div>
        </div>
      )
    } else if (pageDisplayed == 'Companies') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => changePageContent('Benefits')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Benefits'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => goToLink(content.careersLink)}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Careers >'}
            </Text>
          </div>
        </div>
      )
    }
  }

  const showBottomLinks = () => {
    if (pageDisplayed == 'Benefits') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => goToLink(content.careersLink)}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Careers'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Companies')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Companies >'}
            </Text>
          </div>
        </div>
      )
    } else if (pageDisplayed == 'Companies') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => changePageContent('Benefits')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Benefits'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Meet Our People')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Meet Our People >'}
            </Text>
          </div>
        </div>
      )
    }
    return (
      <div className={cx('bottom-links')}>
        <div className={cx('bottom-links-left')} onClick={() => changePageContent('Companies')}>
          <Text tag="p" type="b1" className={cx('bottom-links-text')}>
            {'< Companies'}
          </Text>
        </div>
        <div className={cx('bottom-links-right')} onClick={() => goToLink(content.careersLink)}>
          <Text tag="p" type="b1" className={cx('bottom-links-text')}>
            {'Careers >'}
          </Text>
        </div>
      </div>
    )
  }

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={{ title: content.headerText }}
            smoothScroll={true}
          >
            <div className={cx('page-con')}>
              <div className={cx('hero-con')}>
                <div className={cx('hero-title-con')}>
                  <GradientText
                    tag="h1"
                    className={cx('hero-title')}
                    type="h1"
                    colors={[gradientColor1, gradientColor2, gradientColor3]}
                    colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                  >
                    {'Join'}
                  </GradientText>
                  <GradientText
                    tag="h1"
                    className={cx('hero-title')}
                    type="h1"
                    colors={[gradientColor1, gradientColor2, gradientColor3]}
                    colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                  >
                    {'our team'}
                  </GradientText>
                </div>
                <div className={cx('hero-img-con')}>
                  <Picture
                    className={cx('hero-img')}
                    src={content.headerImage.file.url}
                    alt={content.headerImage.description}
                  />
                </div>
              </div>

              <div className={cx('nav-con')}>
                <div className={cx('nav-link-con')} onClick={() => goToLink(content.careersLink)}>
                  <Text tag="p" type="h3" className={cx('nav-link')}>
                    {'Careers'}
                  </Text>
                </div>
                <div className={cx('nav-link-con')} onClick={() => changePageContent('Benefits')}>
                  <Text
                    tag="p"
                    type="h3"
                    className={pageDisplayed == 'Benefits' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                  >
                    {'Overview of Benefit Plans and Programs'}
                  </Text>
                </div>
                <div className={cx('nav-link-con')} onClick={() => changePageContent('Companies')}>
                  <Text
                    tag="p"
                    type="h3"
                    className={pageDisplayed == 'Companies' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                  >
                    {'Companies'}
                  </Text>
                </div>
                {content.showMeetOurPeopleTab == true && (
                  <div className={cx('nav-link-con')} onClick={() => changePageContent('Meet Our People')}>
                    <Text
                      tag="p"
                      type="h3"
                      className={
                        pageDisplayed == 'Meet Our People' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')
                      }
                    >
                      {'Meet Our People'}
                    </Text>
                  </div>
                )}
              </div>

              <div className={cx('page-content-con')}>
                <div className={cx('content-title-con')}>
                  <GradientText
                    tag="h1"
                    className={cx('content-title')}
                    type="h1"
                    colors={[gradientColor1, gradientColor2, gradientColor3]}
                    colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                  >
                    {pageDisplayed == 'Benefits' ? content.benefitsSubtitle : pageDisplayed}
                  </GradientText>
                </div>
                {pageDisplayed == 'Benefits' ? (
                  ''
                ) : (
                  <div className={cx('content-subtitle-con')}>
                    <GradientText
                      tag="h3"
                      className={cx('content-subtitle')}
                      type="h3"
                      colors={[gradientColor1, gradientColor2, gradientColor3]}
                      colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                    >
                      {pageDisplayed == 'Companies' ? content.companiesSubtitle : ''}
                      {pageDisplayed == 'Meet Our People' ? content.meetOurPeopleSubtitle : ''}
                    </GradientText>
                  </div>
                )}
                <div className={cx('content-paragaph-con')}>
                  <Text tag="p" type="b1" className={cx('content-paragraph')}>
                    {pageDisplayed == 'Companies' ? (
                      <div className={cx('')} dangerouslySetInnerHTML={{ __html: companiesStr }} />
                    ) : (
                      ''
                    )}
                    {pageDisplayed == 'Meet Our People' ? content.meetOurPeopleParagraph.meetOurPeopleParagraph : ''}
                  </Text>
                </div>
                {pageDisplayed == 'Benefits' ? finishBenefitsContent() : ''}
                {pageDisplayed == 'Companies' ? finishCompaniesContent() : ''}
                {pageDisplayed == 'Meet Our People' ? finishMeetOurPeopleContent() : ''}
                {content.showMeetOurPeopleTab ? showBottomLinks() : tempShowBottomLinks()}
              </div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

CareersPage.propTypes = {
  data: PropTypes.object,
}

export default CareersPage
