import CareersPage from '@components/CareersPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulCareersPage {
      headerText
      careersLink
      showMeetOurPeopleTab
      headerImage {
        file {
          url
        }
      }
      aboutUsImage {
        file {
          url
        }
      }
      aboutUsSubtitle
      aboutUsParagraph {
        aboutUsParagraph
      }
      companiesSubtitle
      companiesParagraph {
        json
      }
      meetOurPeopleSubtitle
      meetOurPeopleParagraph {
        meetOurPeopleParagraph
      }
      benefitsSubtitle
      benefitsDescription {
        json
      }
      healthAndWellness {
        healthAndWellness
      }
      workLifeBalance {
        workLifeBalance
      }
      financialWellbeing {
        financialWellbeing
      }
      extras {
        extras
      }
      employeeHighlights {
        name
        image {
          description
          file {
            url
          }
        }
        fullPageImage {
          description
          file {
            url
          }
        }
        cardTitle
        moreInfo {
          json
        }
        jobTitle
        video {
          description
          file {
            url
          }
        }
      }

      hideCompanyImages
      companies {
        companyName
        insuranceCategory
        about {
          json
        }
        companyWebsite {
          url
        }
        logo {
          description
          file {
            url
          }
        }
        marketingImage {
          description
          file {
            url
          }
        }
      }
    }
  }
`

export default CareersPage
