import React from 'react'
import Picture from '@components/Picture'
import GradientText from '@components/GradientText'
import classnames from 'classnames/bind'

import styles from './EmployeeHighlight.module.scss'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const EmployeeHighlight = ({ name, image, cardTitle, richExcerpt, className }) => {
  const handleClick = () => {
    window.location.href = '/highlight/' + name.replace(/ /g, '_')
  }

  return (
    <div>
      <div className={cx('card-gap')}>
        <div
          className={cx('container', 'container-card', className)}
          onClick={() => {
            handleClick()
          }}
        >
          <div className={cx('logo-con')}>
            <Picture
              className={cx('partner-logo')}
              src={image ? image.file.url : '/images/ms/g1001-light-gray.png'}
              alt={image ? image.description : 'Group 1001'}
            />
          </div>

          <div className={cx('vert-flex')}>
            <GradientText
              className={cx('title')}
              tag="h4"
              type="h4"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {cardTitle}
            </GradientText>

            {richExcerpt && <div className={cx('content')} dangerouslySetInnerHTML={{ __html: richExcerpt }} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeHighlight
